<template>
  <div>
    <b-card>
      <b-card-header
        style="padding-top: 0px; padding-right: 0px; padding-left: 0px"
      >
        <div class="d-flex align-items-center">
          <b-card-title>
            <h4><b style="color: #7a2a85">Bukti Transaksi</b></h4>
          </b-card-title>
        </div>
        <b-nav>
          <b-nav-item>
            <router-link to="/user/upgrade_akun">
              <b-button variant="primary">
                <feather-icon icon="ArrowLeftIcon" style="color: white" />
                <span class="align-right"
                  ><b style="color: white"> Kembali</b></span
                >
              </b-button>
            </router-link>
          </b-nav-item>
        </b-nav>
      </b-card-header>

      <b-row class="invoice-preview">
        <!-- Col: Left (Invoice Container) -->
        <b-col cols="12">
          <b-card no-body class="invoice-preview-card">
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">
              <div
                class="
                  d-flex
                  justify-content-between
                  flex-md-row flex-column
                  invoice-spacing
                  mt-0
                "
              >
                <!-- Header: Left Content -->
                <div>
                  <div class="logo-wrapper">
                    <b-img
                      ref="previewEl"
                      rounded
                      :src="require('@/assets/images/avatars/13-small.png')"
                      height="50"
                    />
                    <h3 class="text-primary invoice-logo">Optimal</h3>
                  </div>
                  <p class="card-text mb-25">Kebon Jeruk, Jakarta Barat</p>
                  <p class="card-text mb-25">Jakarta, Indonesia</p>
                  <p class="card-text mb-0">0819-3220-9501</p>
                </div>

                <!-- Header: Right Content -->
                <div class="mt-md-0 mt-2">
                  <h4 class="invoice-title">
                    Invoice
                    <span class="invoice-number">#{{ paket.id }}</span>
                  </h4>
                  <div class="invoice-date-wrapper">
                    <p class="invoice-date-title">Tgl Bayar:</p>
                    <p class="invoice-date">
                      {{ DateNow }}
                    </p>
                  </div>
                  <div class="invoice-date-wrapper">
                    <p class="invoice-date-title">Tempo:</p>
                    <p class="invoice-date">
                      {{ DateNow }}
                    </p>
                  </div>
                </div>
              </div>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing" />

            <!-- Invoice Client & Payment Details -->
            <b-card-body class="invoice-padding pt-0">
              <b-row class="invoice-spacing">
                <!-- Col: Invoice To -->
                <b-col cols="12" xl="6" class="p-0">
                  <h6 class="mb-2">Diberikan Ke:</h6>
                  <h6 class="mb-25">{{ nama }}</h6>
                  <p class="card-text mb-25">{{ kampus }}</p>
                  <p class="card-text mb-25">{{ email }}</p>
                </b-col>

                <!-- Col: Payment Details -->
                <b-col
                  xl="6"
                  cols="12"
                  class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
                >
                  <div>
                    <h6 class="mb-2">Detail Pembayaran:</h6>
                    <table>
                      <tbody>
                        <tr>
                          <td class="pr-1">Total Bayar:</td>
                          <td>
                            <span class="font-weight-bold">{{
                              formatPrice(paket.price)
                            }}</span>
                          </td>
                        </tr>
                        <tr>
                          <td class="pr-1">Nama Bank:</td>
                          <td>Bank BCA</td>
                        </tr>
                        <tr>
                          <td class="pr-1">Negara:</td>
                          <td>Indonesia</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Invoice Description: Table -->
            <b-table-lite responsive :items="pakets" :fields="fields">
              <!-- :fields="['title', 'description', 'price']" -->
              <template #cell(description)="data">
                <b-card-text class="font-weight-bold mb-25">
                  {{ data.item.description }}
                </b-card-text>
              </template>
              <template #cell(price)="data">
                <b-card-text class="font-weight-bold mb-25">
                  Rp {{ formatPrice(data.item.price) }}
                </b-card-text>
              </template>
            </b-table-lite>

            <!-- Invoice Description: Total -->
            <b-card-body class="invoice-padding pb-0">
              <b-row>
                <!-- Col: Sales Persion -->
                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-0 mt-3"
                  order="2"
                  order-md="1"
                >
                  <b-card-text class="mb-0">
                    <span class="font-weight-bold">Oleh:</span>
                    <span class="ml-75">Team Optimal</span>
                  </b-card-text>
                </b-col>

                <!-- Col: Total -->
                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-6 d-flex justify-content-end"
                  order="1"
                  order-md="2"
                >
                  <div class="invoice-total-wrapper">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">Subtotal:</p>
                      <p class="invoice-total-amount">
                        {{ formatPrice(paket.price) }}
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">Discount:</p>
                      <p class="invoice-total-amount">{{formatPrice(priceDiscount)}}</p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">Tax:</p>
                      <p class="invoice-total-amount">0%</p>
                    </div>
                    <hr class="my-50" />
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">Total:</p>
                      <p class="invoice-total-amount">Rp {{ formatPrice(priceBayar)}}</p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing" />

            <!-- Note -->
            <b-card-body class="invoice-padding pt-0">
              <span class="font-weight-bold">Catatan: </span>
              <span>Bayar Sebelum Jatuh Tempo</span>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-card>

    <b-row>
      <b-col cols="8">
        <b-card>
          <b-card-header
            style="padding-top: 0px; padding-right: 0px; padding-left: 0px"
          >
            <div class="d-flex align-items-center">
              <b-card-title>
                <h4><b style="color: #7a2a85">Form Pembayaran</b></h4>
              </b-card-title>
            </div>
          </b-card-header>

          <b-form>
            <b-row>
              <b-col md="6" xl="10" class="mb-1" v-if="cekCodeVoucher != 404">
                <label
                  for="textarea-default"
                  v-for="cek in cekCodeVoucher"
                  :key="cek.id"
                >
                  <b v-if="cek.potongan > 0" style="color: green"
                    >Kode Voucher Tersedia {{ cek.potongan }}</b
                  >
                </label>
              </b-col>
              <b-col
                md="6"
                xl="10"
                class="mb-1"
                v-else-if="cekCodeVoucher == 404"
              >
                <label for="textarea-default">
                  <b style="color: red">Kode Voucher Tidak Ada</b>
                </label>
              </b-col>
              <div v-else></div>
              <b-col md="6" xl="10" class="mb-1">
                <label for="textarea-default">
                  <b>Kode Voucher</b>
                </label>
                <b-form-input
                  id="title"
                  v-model="codevoucher"
                  placeholder="Kode Voucher"
                />
              </b-col>
              <b-col md="6" xl="2" class="mb-1">
                <label for="textarea-default"><b>.</b></label>
                <b-button
                  variant="warning"
                  style="width: 100%"
                  type="button"
                  @click="cekVoucher()"
                >
                  <feather-icon icon="SearchIcon" style="color: white" />
                  <span class="align-middle"
                    ><b style="color: white"> Tambah</b></span
                  >
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6" xl="12" class="mb-1">
                <label for="textarea-default"
                  ><b>Upload Bukti Transfer</b></label
                >
                <b-form-group>
                  <b-form-file
                    v-if="uploadReady"
                    ref="file"
                    type="file"
                    class="form-control"
                    name="file"
                    v-on:change="fileUpload($event.target)"
                  />
                </b-form-group>
                <b-progress
                  v-if="isLoading"
                  animated
                  :value="progressBar"
                  :style="{ width: progressBar + '%' }"
                  show-value
                  :class="'progress-bar-info'"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6" xl="12" class="mb-1">
                <label for="textarea-default"><b>Catatan</b></label>
                <b-form-textarea
                  id="textarea-default"
                  placeholder="Deskripsi"
                  v-model="catatan"
                  rows="2"
                />
              </b-col>
            </b-row>
            <b-col md="6" xl="1" class="mb-1">
              <b-form-input
                hidden
                id="basicInput"
                v-model="paket.photo"
                placeholder="Name File"
              />
            </b-col>
          </b-form>
        </b-card>
      </b-col>
      <b-col cols="4">
        <div class="list-view product-checkout mt-0">
          <div class="checkout-options">
            <b-card>
              <h4><b style="color: #7a2a85">Kalkulasi</b></h4>

              <hr />
              <div class="price-details">
                <h6 class="price-title">Detail Pembayaran</h6>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">Harga Paket:</p>
                  <p class="invoice-date">
                    <b style="color: red">Rp {{formatPrice(paket.price)}}</b>
                  </p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">Potongan Harga:</p>
                  <p class="invoice-date"><b style="color: red">Rp {{formatPrice(priceDiscount)}}</b></p>
                </div>
                <hr />
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">Total Pembayaran</p>
                  <p class="invoice-date">
                    <b style="color: red">Rp {{formatPrice(priceBayar)}}</b>
                  </p>
                </div>
                <b-button variant="primary" block @click="$emit('next-step')">
                  Kirim
                </b-button>
              </div>
            </b-card>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BFormFile,
  BTd,
  BTbody,
  BTfoot,
  BCard,
  BButton,
  BForm,
  BFormInput,
  BToast,
  BCardHeader,
  BNav,
  BCardTitle,
  BFormSelect,
  BNavItem,
  BRow,
  BInputGroup,
  BInputGroupAppend,
  BTableLite,
  BImg,
  BCol,
  BFormGroup,
  BFormTextarea,
} from "bootstrap-vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Swal from "sweetalert2";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    BCard,
    BCardTitle,
    BTableSimple,
    BThead,
    BTr,
    BImg,
    BFormInput,
    quillEditor,
    BForm,
    BFormFile,
    BTh,
    BToast,
    BTd,
    ToastificationContent,
    BTbody,
    Swal,
    BTableLite,
    BTfoot,
    BButton,
    BCardHeader,
    BNav,
    BInputGroup,
    BInputGroupAppend,
    BFormTextarea,
    BNavItem,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    vSelect,
  },
  data() {
    return {
      paket: [],
      pakets: [],
      fields: ["title", "description", "price"],
      id: this.$route.params.id,
      title: "",
      description: "",
      content: "",
      photo: "",
      file: "",
      isEdit: "Tidak",
      isLoading: false,
      progressBar: 0,
      uploadReady: true,
      files: [],
      link: "",
      price: "",
      hidden_file: "",
      getUrl: "",
      DateNow: "",
      catatan: "",
      codevoucher: "",
      nama: localStorage.getItem("Unama"),
      email: localStorage.getItem("Uemail"),
      kampus: localStorage.getItem("Ukampus"),
      cekCodeVoucher: [],
    };
  },
  computed: {
    priceDiscount() {
      const sum = 0;
      if (this.cekCodeVoucher != 404 ) {
      return this.cekCodeVoucher.reduce(
        (sum, total_discount) => sum + total_discount.potongan,
        0
      );
      }else{
        return 0;
      }
    },

    pricePaket() {
      const sum = 0;
      return this.pakets.reduce(
        (sum, total_paket) => sum + total_paket.price,
        0
      );
    },

   priceBayar() {
      const sum = 0;
      return (this.pricePaket - this.priceDiscount)
    },
  },
  async created() {
    await this.$http
      .get(
        process.env.VUE_APP_BACKEND_URL +
          `pakets/search/${this.$route.params.id}`
      )
      .then((response) => {
        this.paket = response.data.data;
        // this.pakets =  Object.values(this.paket);
        this.pakets = Array.of(this.paket);
        // console.log("pakets", this.pakets);
      });

    this.TanggalSekarang();
  },
  async mounted() {
    this.getUrl = process.env.VUE_APP_URL;
    this.cekVoucher()
  },
  methods: {
    async updateData(paket) {
      this.isLoading = true;
      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("title", (this.title = paket.title));
      formData.append("description", (this.description = paket.description));
      formData.append("content", (this.content = paket.content));
      formData.append("hidden_file", (this.photo = paket.photo));
      formData.append("link", (this.link = paket.link));
      formData.append("price", (this.price = paket.price));
      await Swal.fire({
        title: "Apa sudah selesai?",
        text: "Perubahan data disesuaikan",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, sudah selesai!",
      })
        .then((result) => {
          if (result.value) {
            this.$http
              .post(
                process.env.VUE_APP_BACKEND_URL +
                  `pakets/update/${this.$route.params.id}`,
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                  onUploadProgress: function (progressEvent) {
                    this.progressBar = parseInt(
                      Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                      )
                    );
                  }.bind(this),
                }
              )
              .then((result) => {
                this.$router.push({ name: "list_paket" });
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: "Notification",
                      icon: "CheckCircleIcon",
                      text: "Simpan Perubahan Data",
                      variant: "success",
                    },
                  },
                  {
                    timeout: 5000,
                  },
                  {
                    position: "top-right",
                  }
                );
                setTimeout(() => {
                  this.photoReset();
                  this.isLoading = false;
                  this.progressBar = 0;
                }, 2000);
              });
          } else {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: "Notification",
                  icon: "CheckCircleIcon",
                  text: "Batal Perubahan Data",
                  variant: "warning",
                },
              },
              {
                timeout: 5000,
              },
              {
                position: "top-right",
              }
            );
          }
        })
        .then((result) => {})
        .catch((err) => {
          console.log(err);
        });
    },
    cancelButton() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "CheckCircleIcon",
            text: "Batal Ubah Data",
            variant: "info",
          },
        },
        {
          timeout: 5000,
        },
        {
          position: "top-right",
        }
      );
      this.$router.push({ name: "/dashboard" });
      setTimeout(() => {
        this.photoReset();
        this.isLoading = false;
        this.progressBar = 0;
      }, 2000);
    },
    fileUpload(event) {
      this.file = event.files[0];
    },
    photoReset() {
      this.uploadReady = false;
      this.$nextTick(() => {
        this.uploadReady = true;
      });
    },
    TanggalSekarang() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();

      var today = mm + "/" + dd + "/" + yyyy;
      // var currentdate = new Date();
      this.DateNow = today;
    },
    formatPrice(value) {
      if (value != null) {
        const val = Number(value).toFixed(2).replace(/\.00$/, "");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      }
    },
    cekVoucher() {
      const formData = new FormData();
      formData.append("codevoucher", this.codevoucher);
      this.$http
        .post(
          process.env.VUE_APP_BACKEND_URL + `discounts/search_code`,
          formData
        )
        .then(
          (result) => {
            this.cekCodeVoucher = result.data.data;
            // console.log("cek test", this.cekCodeVoucher);
          },
          (error) => {
            console.error(error);
          }
        );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>